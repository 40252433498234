const labelsData = [
  {
    id: 1,
    label: 'Je reçois trop d\'emails',
  },
  {
    id: 2,
    label: 'Cela ne m\'intéresse plus',
  },
  {
    id: 3,
    label: 'Je n\'ai jamais demandé à recevoir ces emails',
  },
  {
    id: 4,
    label: 'Autre',
  },
];

export default labelsData;
