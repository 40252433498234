import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PuffLoader } from 'react-spinners';

const Loader = ({ color, loading }) => {
  const loaderCSS = useMemo(() => ({
    position: 'absolute',
    top: 'calc(50% - 40px)',
    left: 'calc(50% - 40px)',
  }), []);

  const colorDef = {
    violet: '#DD0369',
    yellow: '#F0B40E',
    white: '#FFF',
  };

  return (
    <PuffLoader
      color={colorDef[color]}
      loading={loading}
      cssOverride={loaderCSS}
      size={80}
    />
  );
};

Loader.propTypes = {
  color: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Loader;
