import logoLivementor from '../assets/img/logo-livementor.webp';
import logoGenuine from '../assets/img/logo-genuine.webp';
import logoTuba from '../assets/img/logo-tuba.webp';
import logoSorority from '../assets/img/logo-sorority.webp';
import logoLesEpaulettes from '../assets/img/logo-les-epaulettes.webp';

const teamWkcData = [
  {
    image: logoLivementor,
    alt: 'Livementor',
  },
  {
    image: logoGenuine,
    alt: 'Genuine',
  },
  {
    image: logoTuba,
    alt: 'Tuba',
  },
  {
    image: logoSorority,
    alt: 'Sorority Club',
  },
  {
    image: logoLesEpaulettes,
    alt: 'Les Epaulettes',
  },
];

export default teamWkcData;
