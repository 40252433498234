import teamBlandine from '../assets/img/team-blandine.webp';
import teamSandrine from '../assets/img/team-sandrine.webp';
import teamBertrand from '../assets/img/team-bertrand.webp';
import teamVanessa from '../assets/img/team-vanessa.webp';

const teamWkcData = [
  {
    image: teamBlandine,
    alt: 'Blandine',
  },
  {
    image: teamSandrine,
    alt: 'Sandrine',
  },
  {
    image: teamBertrand,
    alt: 'Bertrand',
  },
  {
    image: teamVanessa,
    alt: 'Vanessa',
  },
];

export default teamWkcData;
