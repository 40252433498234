import { BrowserRouter } from 'react-router-dom';

import Router from '../components/Router';
import Header from '../components/Header';
import Footer from '../components/Footer';

import './styles.scss';

const App = () => (
  <main className="main">
    <BrowserRouter>
      <Header />
      <Router />
      <Footer />
    </BrowserRouter>
  </main>
);

export default App;
