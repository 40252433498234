import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

import faviconWkCWhite from '../../assets/img/favicon-wkc-white.webp';
import { pageItems, socialItems } from './linkItems';

import './styles.scss';

const Footer = () => {
  const thisYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className="footer">
      <Container>
        <Row className="py-3 text-white">
          <Col
            xs={{ span: 12, order: 3 }}
            md={{ span: 4, order: 1 }}
            className="footer__copyright"
          >
            <Image
              className="logo"
              src={faviconWkCWhite}
              fluid
              loading="lazy"
              decoding="async"
            />
            <small>&copy;{thisYear} Workin'Cool EURL</small>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 4, order: 2 }}
            className="footer__social"
          >
            {
              socialItems.map(({ link, icon }) => (
                <Link
                  key={link}
                  to={link}
                  target={link.slice(0, 2) === '//' ? '_blank' : ''}
                >
                  {icon}
                </Link>
              ))
            }
          </Col>
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 4, order: 3 }}
            className="footer__links"
          >
            {
              pageItems.map(({ label, link }) => (
                <Link
                  key={link}
                  to={link}
                  className="link"
                >
                  <small>{label}</small>
                </Link>
              ))
            }
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
