import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button, Collapse, Card } from 'react-bootstrap';

import { IconContext } from 'react-icons';
import { FaPlusCircle } from 'react-icons/fa';

const CollapsingMenu = ({ color, label, content }) => {
  const [open, setOpen] = useState(false);
  const plusCSS = useMemo(() => ({
    size: '1.5rem',
    className: 'me-2',
  }), []);

  return (
    <>
      <Button
        variant={`wkc-${color}`}
        aria-controls="clarity-collapse"
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        <span className="d-flex align-items-center fs-4">
          <IconContext.Provider value={plusCSS}>
            <FaPlusCircle />
          </IconContext.Provider>
          <strong className="text-uppercase">{label}</strong>
        </span>
      </Button>
      <Collapse in={open}>
        <div id="clarity-collapse">
          {
            content.map(({ title, text }) => (
              <Card className={`bg-wkc-${color} my-3 text-white border--${color}`} key={`${title}-${text}`}>
                <Card.Body>
                  <Card.Title className="text-uppercase ff--bold">
                    {title}
                  </Card.Title>
                  <Card.Text>
                    {text}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))
          }
        </div>
      </Collapse>
    </>
  );
};

CollapsingMenu.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.element,
  })).isRequired,
};

export default CollapsingMenu;
