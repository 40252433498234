import slideOne from './slide-one.webp';
import slideTwo from './slide-two.webp';
import slideThree from './slide-three.webp';
import slideFour from './slide-four.webp';

const carouselAppData = [
  {
    image: slideOne,
    caption: 'Toutes tes ressources au même endroit',
  },
  {
    image: slideTwo,
    caption: 'Le calendrier des évènements',
  },
  {
    image: slideThree,
    caption: 'Les replays des lives',
  },
  {
    image: slideFour,
    caption: 'Un espace d\'échange avec les coachs',
  },
];

export default carouselAppData;
