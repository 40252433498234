import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Sling as Hamburger } from 'hamburger-react';

import {
  Container, Nav, Navbar, Image, NavDropdown, Button,
} from 'react-bootstrap';

import logoWkCViolet from '../../assets/img/logo-wkc-violet.webp';

import { solutions, resources, rightNav } from './linkItems';

import './styles.scss';

const Header = () => {
  const [openBurger, setOpenBurger] = useState(false);

  const handleSelect = () => {
    setOpenBurger(false);
  };
  const { pathname } = useLocation();

  const updateActiveClass = () => {
    const links = document.querySelectorAll('.header a[data-route]');
    links.forEach((link) => {
      if (link.getAttribute('data-route') !== pathname) {
        link.classList.remove('active');
      } else {
        link.classList.add('active');
      }
    });
  };

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          updateActiveClass();
        }
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, [pathname]);

  useEffect(() => {
    updateActiveClass();
  }, [pathname]);

  useEffect(() => {
    const dropdownToggles = document.querySelectorAll('a.dropdown-toggle');
    dropdownToggles.forEach((toggle) => {
      toggle.removeAttribute('href');
    });
  }, []);

  return (
    <header className="header">
      <Navbar
        className="bg-white"
        collapseOnSelect
        expand="lg"
        fixed="top"
      >
        <Container>
          <Navbar.Brand as={Link} to="">
            <Image
              className="header__logo"
              src={logoWkCViolet}
              fluid
              loading="lazy"
              decoding="async"
            />
          </Navbar.Brand>
          <Navbar.Toggle className="header__toggler" aria-controls="navbar">
            <Hamburger
              toggled={openBurger}
              toggle={setOpenBurger}
              size={25}
              duration={1}
              rounded
              color="#DD0369"
            />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbar">
            <Nav
              className="header__main-nav me-auto"
              onSelect={handleSelect}
            >
              <NavDropdown
                title={
                  <span className="dropdown-title">Nos solutions</span>
                }
                className="mx-3"
              >
                {
                  solutions.map(({ label, link, target }) => (
                    <NavDropdown.Item
                      key={link}
                      as={Link}
                      eventKey={link}
                      to={link}
                      target={target}
                      data-route={link}
                    >
                      {label}
                    </NavDropdown.Item>
                  ))
                }
              </NavDropdown>
              <NavDropdown
                title={
                  <span className="dropdown-title">Nos ressources</span>
                }
                className="mx-3"
              >
                {
                  resources.map(({ label, link, target }) => (
                    target === '_blank' ? (
                      <NavDropdown.Item
                        key={link}
                        as={Link}
                        to={link}
                        target={target}
                        data-route={link}
                      >
                        {label}
                      </NavDropdown.Item>
                    ) : (
                      <NavDropdown.Item
                        key={link}
                        as={Link}
                        eventKey={link}
                        to={link}
                        target={target}
                        data-route={link}
                      >
                        {label}
                      </NavDropdown.Item>
                    )
                  ))
                }
              </NavDropdown>
            </Nav>
            <Nav
              className="header__right-nav"
              onSelect={handleSelect}
            >
              {
                rightNav.map(({ label, link, target }) => (
                  <Nav.Link
                    key={link}
                    as={Link}
                    eventKey={link}
                    to={link}
                    target={target}
                    data-route={link}
                  >
                    {
                        link === '/nous-rencontrer' ? (
                          <Button variant="wkc-violet">
                            <small className="text-uppercase">{label}</small>
                          </Button>
                        ) : label
                      }
                  </Nav.Link>
                ))
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
