import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Carousel, Card } from 'react-bootstrap';

import { IconContext } from 'react-icons';
import { FaChevronCircleRight, FaChevronCircleLeft } from 'react-icons/fa';

import './styles.scss';

const CarouselTestimonials = ({ borderColor, controlColor, carouselTestimonialsData }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const arrowCSS = useMemo(() => ({
    className: 'arrow',
  }), []);

  return (
    <Carousel
      interval={5000}
      activeIndex={index}
      onSelect={handleSelect}
      className={`carousel-testimonials control--${controlColor}`}
      nextIcon={(
        <IconContext.Provider value={arrowCSS}>
          <FaChevronCircleRight />
        </IconContext.Provider>
    )}
      prevIcon={(
        <IconContext.Provider value={arrowCSS}>
          <FaChevronCircleLeft />
        </IconContext.Provider>
    )}
    >
      {
        carouselTestimonialsData.map(({
          id, name, role, pitch,
        }) => (
          <Carousel.Item
            key={id}
            className="carousel-testimonials__item"
          >
            <Card className={`border--${borderColor}`}>
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Text className="fst-italic">
                  {pitch}
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Card.Title className="text-center">
                  {name} - {role}
                </Card.Title>
              </Card.Footer>
            </Card>
          </Carousel.Item>
        ))
    }
    </Carousel>
  );
};

CarouselTestimonials.propTypes = {
  borderColor: PropTypes.string.isRequired,
  controlColor: PropTypes.string.isRequired,
  carouselTestimonialsData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    pitch: PropTypes.element,
  })).isRequired,
};

export default CarouselTestimonials;
