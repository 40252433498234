const googleAdviseData = [
  {
    rank: 5,
    advise: '"J’ai retrouvé le cœur qui palpite, l’envie et les yeux qui pétillent."',
    user: 'Solène, Dirigeante',
  },
  {
    rank: 5,
    advise: '"J’ai réussi à dépasser des blocages ancrés, je me sens beaucoup mieux dans mon quotidien !"',
    user: 'Romain, Chef d’entreprise',
  },
  {
    rank: 5,
    advise: '"J’ai réorganisé ma vie et mon travail. J’ai retrouvé du plaisir dans mon quotidien de maman et de femme."',
    user: 'Charlotte, Cheffe de Projet',
  },
];

export default googleAdviseData;
