const collapsingMenuData = [
  {
    color: 'blue',
    label: 'Clareté',
    content: [
      {
        title: 'Clareté',
        text: (
          <>
            Tu as une <strong>vision claire</strong> de ce que tu veux pour ton business
            ET ta vie. Tu sais quelles actions mettre en place pour l’obtenir.
          </>
        ),
      },
      {
        title: 'Fluidité',
        text: (
          <>
            Tu n’es plus paralysé·e face aux
            difficultés, <strong>tu les débloques chaque jour</strong> plus facilement.
          </>
        ),
      },
      {
        title: 'Organisation',
        text: (
          <>
            Tu organises tes semaines
            avec <strong>stratégie</strong> et <strong>flexibilité</strong>.
          </>
        ),
      },
    ],
  },
  {
    color: 'violet',
    label: 'Maîtrise du temps',
    content: [
      {
        title: 'Maîtrise du temps',
        text: (
          <>
            Tu <strong>gères le temps</strong> et l’énergie que tu investis dans
            tes projets pour une meilleure rentabilité et
            beaucoup moins de charge mentale.
          </>
        ),
      },
      {
        title: 'Sérénité',
        text: (
          <>
            Tu te sens pleinement <strong>épanoui·e</strong> pour te
            donner les moyens de tes ambitions.
          </>
        ),
      },
      {
        title: 'Confiance',
        text: (
          <>
            Tu te sens plus <strong>légitime </strong>
            et <strong>confiant·e</strong> au quotidien.
          </>
        ),
      },
    ],
  },
  {
    color: 'darkviolet',
    label: 'Esprit d\'équipe',
    content: [
      {
        title: 'Esprit d\'équipe',
        text: (
          <>
            Tu profites d’échanges <strong>déculpabilisants</strong> avec
            d’autres entrepreneurs et avance en équipe.
          </>
        ),
      },
      {
        title: 'Dynamisme',
        text: (
          <>
            Tu avances en <strong>maîtrisant </strong>
            pleinement <strong>ton temps et ton énergie</strong>.
          </>
        ),
      },
      {
        title: 'Plaisir',
        text: (
          <>
            Tu <strong>prends du plaisir</strong> dans ton quotidien et trouves
            la sérénité que tu espères depuis si longtemps.
          </>
        ),
      },
    ],
  },
];

export default collapsingMenuData;
