import { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Container, Row, Col, Image, Card, Button,
} from 'react-bootstrap';

import { IconContext } from 'react-icons';
import { FaCheckCircle } from 'react-icons/fa';
import { IoChatboxEllipses } from 'react-icons/io5';
import { SlSocialInstagram } from 'react-icons/sl';
import { GrLinkedin } from 'react-icons/gr';

import { InlineWidget } from 'react-calendly';
import vanessaDesk from '../../assets/img/vanessa-desk.webp';
import coffee from '../../assets/img/coffee.webp';

import GoogleAdvise from './GoogleAdvise';
import googleAdviseData from './GoogleAdvise/googleAdviseData';

import useScrollToTop from '../../hooks/useScrollToTop';
import useBreakpoint from '../../hooks/useBreakpoint';

import { googleAdviseLink, linkedinLink, instagramLink } from '../../components/Router/routes';

import ContactForm from '../../components/ContactForm';

import sendMessage from '../../services/message';

import './styles.scss';

const MeetUs = () => {
  const checkRoundYellowCSS = useMemo(() => ({
    size: '1.2rem',
    className: 'me-2 text-wkc-yellow',
  }), []);

  const chatCSS = useMemo(() => ({
    size: '1.5rem',
    className: 'ms-2 text-white',
  }), []);

  const socialCSS = useMemo(() => ({
    size: '3rem',
    className: 'mx-5 text-wkc-yellow',
  }), []);

  const breakpoint = useBreakpoint();
  useScrollToTop();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const handleSendMessage = async (firstname, lastname, email, message) => {
    try {
      setLoading(true);
      setSuccess(false);
      setFailed(false);
      const {
        sendMessageSuccess,
        sendMessageError,
      } = await sendMessage(firstname, lastname, email, message);
      if (sendMessageError) {
        throw new Error(sendMessageError);
      }
      if (process.env.NODE_ENV === 'development') {
        const { log } = console;
        log(sendMessageSuccess);
      }
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  useEffect(() => {
    if (failed) {
      setTimeout(() => {
        setLoading(false);
        setSuccess(false);
        setFailed(false);
      }, 3000);
    }
  }, [failed]);
  return (
    <div className="meet-us">
      <header className="meet-us__header">
        <Container fluid>
          <Row>
            <Col xs={12} md={7} className="position-relative d-flex flex-column justify-content-end align-items-end bg-wkc-blue pe-0">
              <div className="title-container text-white">
                <p className="mb-0">
                  Que vous soyez chef d'entreprise, manager ou RH
                </p>
                <h1 className="ff--bold fs-2">
                  Rencontrez un Coach<br />
                  Workin'Cool
                </h1>
              </div>
              <Image
                className="vanessa"
                src={vanessaDesk}
                alt="Vanessa"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col xs={12} md={5} className="d-flex flex-column justify-content-end align-items-center">
              <Image
                className="coffee"
                src={coffee}
                alt="Café papote"
                fluid
                loading="lazy"
                decoding="async"
              />
              <Card className="border--blue my-5">
                <Card.Body>
                  <h4 className="ff--bold">
                    <span className="text-wkc-yellow">30 min</span> pour faire connaissance
                  </h4>
                  <ul className="ps-0 mb-0">
                    <li className="ff--bold d-flex align-items-center">
                      <div>
                        <IconContext.Provider value={checkRoundYellowCSS}>
                          <FaCheckCircle />
                        </IconContext.Provider>
                      </div>
                      Faire le point sur votre situation
                    </li>
                    <li className="ff--bold d-flex align-items-center">
                      <div>
                        <IconContext.Provider value={checkRoundYellowCSS}>
                          <FaCheckCircle />
                        </IconContext.Provider>
                      </div>
                      Débloquer les urgences
                    </li>
                    <li className="ff--bold d-flex align-items-center">
                      <div>
                        <IconContext.Provider value={checkRoundYellowCSS}>
                          <FaCheckCircle />
                        </IconContext.Provider>
                      </div>
                      Et définir ensemble votre stratégie
                    </li>
                  </ul>
                </Card.Body>
              </Card>
              <div className="mt-3 text-center">
                <h4 className="ff--bold text-uppercase mb-0">
                  <span className="lighted--yellow text-wkc-black">
                    Pour 0€
                  </span>
                </h4>
                <p className=" text-uppercase">
                  sans engagement
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <section className="meet-us__section-1 py-5 bg-wkc-violet">
        <Container>
          <Row className="justify-content-center text-white">
            <Col xs={12} md={4} className="text-center text-md-start">
              <p className="fs-4 ff--bold">
                L’étape #1 vers une activité<br />
                perfomante SEREINEMENT<br />
                et SANS sacrifices ?
              </p>
              <p className="fs-4 ff--bold mb-0">
                <span className="lighted--yellow text-uppercase">
                  le café papote
                </span>
                <br />
                100% cool & 0 engagement !
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="meet-us__section-2 py-5">
        <Container>
          <Row>
            <Col xs={12} className="px-0">
              <InlineWidget
                url="https://calendly.com/vanessa-workincool/rendez-vous-avec-vanessa-workin-cool"
                styles={{
                  height: breakpoint > 768 ? '800px' : '1000px',
                  display: 'block',
                  margin: '0',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="meet-us__section-3 py-5">
        <Container>
          <Row>
            <Col xs={12} md={3}>
              <h3>
                Tout à commencé avec un Café Papote
              </h3>
              <Link
                to={googleAdviseLink}
                target="_blank"
              >
                <Button variant="wkc-violet">
                  Voir plus d'avis
                </Button>
              </Link>
            </Col>
            <GoogleAdvise googleAdviseData={googleAdviseData} />
          </Row>
        </Container>
      </section>
      <section className="meet-us__section-4 py-5 bg-wkc-darkviolet">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <h3 className="text-white text-center">
                <strong>
                  Tu peux aussi nous contacter directement<br className="d-none d-md-block" />
                  via les réseaux
                  <IconContext.Provider value={chatCSS}>
                    <IoChatboxEllipses />
                  </IconContext.Provider>
                </strong>
              </h3>
              <div className="text-center my-3">
                <Link
                  to={instagramLink}
                  target="_blank"
                >
                  <IconContext.Provider value={socialCSS}>
                    <SlSocialInstagram />
                  </IconContext.Provider>
                </Link>
                <Link
                  to={linkedinLink}
                  target="_blank"
                >
                  <IconContext.Provider value={socialCSS}>
                    <GrLinkedin />
                  </IconContext.Provider>
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center my-5">
            <Col xs={12} md={6}>
              <h3 className="text-white text-center">
                <strong>
                  Ou nous laisser un message :
                </strong>
              </h3>
              <ContactForm
                submitForm={handleSendMessage}
                loading={loading}
                success={success}
                error={failed}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default MeetUs;
