import PropTypes from 'prop-types';

import { Card, Image } from 'react-bootstrap';

import './styles.scss';

const EventCard = ({
  picto, mainColor, secondaryColor, frequency, subtitle, content,
}) => (
  <Card className={`event-card bg-${secondaryColor} border--${mainColor}`}>
    <Card.Header className={`bg-${secondaryColor}`}>
      <Card.Title>
        {frequency}
      </Card.Title>
      <Image
        className="event-picto"
        src={picto}
        alt="Pictogramme de l'évènement"
        fluid
        loading="lazy"
        decoding="async"
      />
    </Card.Header>
    <Card.Body className="bg-white border-bottom--rounded">
      <Card.Title className={`text-wkc-${mainColor} text-center mb-3`}>
        {subtitle}
      </Card.Title>
      {content}
    </Card.Body>
  </Card>
);

EventCard.propTypes = {
  picto: PropTypes.string.isRequired,
  mainColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  frequency: PropTypes.element.isRequired,
  subtitle: PropTypes.element.isRequired,
  content: PropTypes.element.isRequired,
};

export default EventCard;
