const FaqData = [
  {
    title: 'Quand ouvrent les portes ?',
    content: (
      <p>
        La prochaine cohorte est prévue en <strong>Novembre 2024</strong>.<br />
        Réserve ta place pour être sûr·e de profiter du tarif early bird.
      </p>
    ),
  },
  {
    title: 'Pourquoi dois-je réserver un call ?',
    content: (
      <>
        <p>
          Il est primordial pour nous de mieux connaître ta situation,
          ton business, et tes challenges actuels pour déterminer si
          My Cool’Pilot est la solution dont tu as besoin pour obtenir
          les résultats que tu recherches.
        </p>
        <p>
          En intégrant l’accompagnement, tu rejoins une communauté d’entrepreneurs.
          Partager ce moment avec chacun d’entre vous, nous assure d’une bonne
          cohérence du groupe. Pour que vous puissiez tous vous sentir pleinement
          épanoui et à votre place dans le programme.
        </p>
        <p>
          Enfin, on voit si le feeling passe entre nous.
          Pour être une équipe qui marche bien, c'est super important.
        </p>
        <p>
          Il est offert et ne t’engage à rien, à part passer un moment sympathique ensemble.
        </p>
      </>
    ),
  },
  {
    title: 'Est-ce que c’est compatible avec mon emploi du temps chargé ?',
    content: (
      <p>
        OUI ! L’accompagnement My Cool’Pilot a été conçu pour des entrepreneur·es et
        dirigeant·es par une équipe d’entrepreneurs. Nous connaissons parfaitement
        ton quotidien mouvementé (nous vivons le même 😉), c’est pour cela que nous
        te proposons plusieurs RDV par mois pour que tu aies toujours l’opportunité
        d’en profiter. Tous les évènements sont disponibles en replay si besoin, et
        MY COOL’APP te permet de bénéficier de contenus disponibles en autonomie,
        afin que tu puisses y consacrer le temps que tu souhaites,
        sans pression et avancer à ton rythme.
      </p>
    ),
  },
  {
    title: 'Est-ce que je peux intégrer les frais à mes dépenses professionnelles ?',
    content: (
      <>
        <p>
          OUI. Tu peux choisir d’intégrer le coût du programme à tes frais professionnels.
          C’est parfaitement justifié par rapport aux bénéfices que tu peux en attendre dans
          ton développement professionnel et ta capacité à faire croître ton activité,
          ton équipe et ton CA tout en préservant ton équilibre de vie et ton bien-être.
        </p>
        <p>
          Une facture sera mise à ta disposition à chaque échéance dans ton espace personnel.
        </p>
      </>
    ),
  },
  {
    title: 'J’ai déjà été accompagné·e, est-ce adapté pour moi ?',
    content: (
      <>
        <p>
          Bravo ! C’est super que tu aies déjà franchi le cap de l’accompagnement,
          c’est essentiel pour un·e chef·fe d’entreprise.
        </p>
        <p>
          Chaque expérience t’apporte des choses différentes, et donc des résultats
          différents d’autant qu’à chaque stade du développement
          de ton entreprise les enjeux évoluent.
        </p>
        <p>
          Qui plus est, My Cool’Pilot n’est pas qu’un simple accompagnement.
        </p>
        <p>
          Parce qu’il a été conçu pour allier différentes expériences d’accompagnement :
        </p>
        <ul>
          <li> ● Les coachings de groupe, individuel et par écrit.</li>
          <li> ● Les ateliers d’intelligence collective entre entrepreneur·es.</li>
          <li> ● Les masterclass thématiques.</li>
          <li> ● Les échanges et des connexions au sein du groupe.</li>
          <li>
            ● Et tous les contenus vidéos, audio et écrits à ta disposition sur MY COOL’ APP 😀
          </li>
        </ul>
        <p>
          Cette méthodologie te permet de bénéficier d’un accompagnement et d’un
          soutien constant pour débloquer tes problématiques au quotidien et ne
          plus jamais te retrouver seul·e dans l’impasse.
        </p>
      </>
    ),
  },
  {
    title: 'Est-ce que c\'est une formation en ligne ?',
    content: (
      <>
        <p>
          My Cool’Pilot est un accompagnement avec des vraies personnes à qui tu vas pouvoir parler.
        </p>
        <p>
          Le format digital et en ligne nous permet d’aider un maximum de personnes
          sans contraintes de lieu ou de temps, mais ce n’est pas une formation où tu te
          retrouves seul·e face à ton écran, car tu participes TOUS LES MOIS à des lives,
          des rencontres, des sessions de coaching (individuel ou collectif) qui sont
          données en direct et accessibles en replay.
        </p>
      </>
    ),
  },
  {
    title: 'Je suis indépendant·e, coach ou freelance, est-ce que je peux m’inscrire ?',
    content: (
      <>
        <p>
          OUI ! My Cool’Pilot s’adresse à tout les entrepreneur·es qui sont déjà
          lancé·es et qui souhaitent passer un cap business et atteindre leurs
          objectifs de manière plus efficace et durable.
        </p>
        <p>
          Il n’y a pas de “niveau” de CA pour intégrer le programme.
        </p>
        <p>
          Si tu as un doute, la meilleure chose à faire est d’en parler ensemble
          pour voir si l’accompagnement est vraiment fait pour toi.
        </p>
        <p>
          Mais franchement...<br />
          Si la page t’a amené jusqu’ici, et surtout, si elle t’a parlé, tu as ta réponse 🙂
        </p>
      </>
    ),
  },
  {
    title: 'Durant les 6 mois d\'accompagnement, je ne risque pas de m’ennuyer ?',
    content: (
      <p>
        Le programme My Cool’Pilot est conçu de telle manière que tu auras toujours
        de nouvelles ressources pour t’accompagner dans ton quotidien d’entrepreneur.
        En plus, c’est toi qui apporte tes problématiques en session de travail
        et nous nous servons de vos retours d’expérience pour créer les masterclass
        et enrichir l’accompagnement. Ce qui nous permet de toujours mieux répondre à vos besoins.
      </p>
    ),
  },
];

export default FaqData;
