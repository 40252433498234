import {
  sellPageMyCoolPilotLink,
  sellPageHappyCultureLink,
  newsletterLink,
  workinDiagLink,
  podcastLink,
  meetUsLink,
  aboutUsLink,
} from '../Router/routes';

export const solutions = [
  {
    label: 'My Cool\' Pilot',
    link: sellPageMyCoolPilotLink,
    target: '',
  },
  {
    label: 'Happy\' Culture',
    link: sellPageHappyCultureLink,
    target: '',
  },
];

export const resources = [
  {
    label: 'Mails privés',
    link: newsletterLink,
    target: '',
  },
  {
    label: 'Workin\'Diag',
    link: workinDiagLink,
    target: '_blank',
  },
  {
    label: 'Podcast La Grande Roue',
    link: podcastLink,
    target: '',
  },
];

export const rightNav = [
  {
    label: 'À propos',
    link: aboutUsLink,
    target: '',
  },
  {
    label: 'Nous rencontrer',
    link: meetUsLink,
    target: '',
  },
];
