import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button, Collapse } from 'react-bootstrap';

import { IconContext } from 'react-icons';
import { IoCaretDownCircle } from 'react-icons/io5';

import './styles.scss';

const Faq = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  const caretCSS = useMemo(() => ({
    size: '1.3rem',
  }), []);

  return (
    <div className="faq my-3">
      <Button
        variant="wkc-violet"
        aria-controls="clarity-collapse"
        aria-expanded={open}
        onClick={() => setOpen(!open)}
        className="d-flex justify-content-between"
      >
        <strong>{title}</strong>
        <div>
          <IconContext.Provider value={caretCSS}>
            <IoCaretDownCircle />
          </IconContext.Provider>
        </div>
      </Button>
      <Collapse in={open}>
        <div id="clarity-collapse" className="mt-2">
          {content}
        </div>
      </Collapse>
    </div>
  );
};

Faq.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
};

export default Faq;
