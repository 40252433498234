import {
  Routes, Route, Navigate,
} from 'react-router-dom';

import linkItems from './linkItems';

const Router = () => (
  <Routes>
    {
      linkItems.map(({ link, view }) => (
        <Route
          key={link}
          path={link}
          element={view}
        />
      ))
    }
    <Route
      path="*"
      element={<Navigate to="/" />}
    />
  </Routes>
);

export default Router;
