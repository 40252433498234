import { useState, useMemo, useRef } from 'react';

import {
  Container, Row, Col, Image, Collapse,
} from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from 'react-icons/io';

import useScrollToTop from '../../hooks/useScrollToTop';
import useBreakpoint from '../../hooks/useBreakpoint';
import useOnClickOutside from '../../hooks/useOnClickOutside';

import podcastTitle from '../../assets/img/podcast-title.webp';
import podcastLogo from '../../assets/img/podcast-logo.webp';
import Loader from '../../components/Loader';

import './styles.scss';

const Podcast = () => {
  useScrollToTop();

  const breakpoint = useBreakpoint();

  const [readMore, setReadMore] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const arrowCSS = useMemo(() => ({
    size: '1.5rem',
    className: 'ms-2',
  }), []);

  const ref = useRef();
  useOnClickOutside(ref, () => setReadMore(false));

  return (
    <div className="podcast">
      <header className="podcast__header pt-5 pb-3">
        <Container>
          <Row>
            <Col xs={0} md={3} />
            <Col xs={12} md={6} className="text-center">
              <Image
                className="podcast-title"
                src={podcastTitle}
                alt="Podcast la Grande Roue"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col xs={12} md={3} className="d-flex align-items-end justify-content-end justify-content-md-start">
              <Image
                className="podcast-logo"
                src={podcastLogo}
                alt="Logo la Grande Roue"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
          </Row>
          <Row className="justify-content-center my-4">
            <Col ref={ref} xs={12} md={8}>
              <p className="text-center">
                <strong>La vie d'entrepreneur, c’est un drôle de manège.</strong>
              </p>
              <p className={readMore ? '' : 'first-line'}>
                Il y a des hauts et des bas.
                Parfois plus de bas que de hauts.
              </p>
              <Collapse in={readMore}>
                <div>
                  <p>
                    Ce qui est sûr, c’est qu’une fois qu'on a fait un tour dans la grande roue
                    de l'entreprenariat, on a plus du tout envie d’en descendre.
                  </p>
                  <p>
                    Bienvenue dans le podcast La Grande Roue.
                    Je suis Vanessa Rocherieux et je reçois ici des entrepreneurs
                    et dirigeants passionnés.
                    Ils ont accepté de venir jouer le jeu de La Grande Roue et
                    de nous livrer un petit bout de leur quotidien et d'eux-mêmes
                    en toute transparence.
                  </p>
                  <p>
                    Ils ne savent pas quel sujet va être abordé, moi non plus d’ailleurs.
                    Nous le découvrirons tous ensemble en tournant la roue.
                    Ici on parle sans tabou de la vraie vie des entrepreneurs avec
                    tous ses contrastes et ses contradictions.
                    Tu pourras puiser dans les expériences de mes invités pour t’aider à
                    mieux vivre et gérer les aléas de ton quotidien, et avancer sur les
                    sujets qui seront abordés, à ton rythme et sans pression.
                    Alors bienvenue à toi, bienvenue à bord de La Grande Roue !
                  </p>
                </div>
              </Collapse>
              <p>
                <span
                  className="collapse-trigger"
                  onClick={() => setReadMore(!readMore)}
                >
                  {
                    readMore ? (
                      <>
                        j'ai lu
                        <IconContext.Provider value={arrowCSS}>
                          <IoIosArrowDropupCircle />
                        </IconContext.Provider>
                      </>
                    ) : (
                      <>
                        Lire la suite
                        <IconContext.Provider value={arrowCSS}>
                          <IoIosArrowDropdownCircle />
                        </IconContext.Provider>
                      </>
                    )
                  }
                </span>
              </p>
            </Col>
          </Row>
        </Container>
      </header>
      <main className="podcast__main">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8} className="position-relative">
              {
                isLoading && <Loader color="violet" loading={isLoading} />
              }
              <iframe
                name="Ausha Podcast Player"
                loading="lazy"
                id="ausha-ndQp"
                title="La Grande Roue"
                onLoad={() => setIsLoading(false)}
                style={{
                  border: 'none',
                  width: '100%',
                  height: breakpoint > 768 ? '420px' : '720px',
                }}
                src="https://player.ausha.co/?showId=yAGWMeiGa8rx&color=%23DD0369&playlist=true&v=3&playerId=ausha-ndQp"
              />
              <script src="https://player.ausha.co/ausha-player.js" />
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default Podcast;
