import PropTypes from 'prop-types';

import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

import './styles.scss';

const TeamWkc = ({ teamWkcData }) => (
  <div className="team-wkc my-5">
    <Container>
      <Row>
        {
          teamWkcData.map(({ image, alt }) => (
            <Col xs={12} md={3} className="mb-4 text-center" key={alt}>
              <Image
                className="team-member"
                src={image}
                alt={alt}
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
          ))
        }
      </Row>
    </Container>
  </div>
);

TeamWkc.propTypes = {
  teamWkcData: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    alt: PropTypes.string,
  })).isRequired,
};

export default TeamWkc;
