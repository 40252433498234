import strategyPillar from '../../../assets/img/strategy-pillar.webp';
import efficiencyPillar from '../../../assets/img/efficiency-pillar.webp';
import mindsetPillar from '../../../assets/img/mindset-pillar.webp';
import emotionsPillar from '../../../assets/img/emotions-pillar.webp';

const pillarCardData = [
  {
    number: 1,
    title: 'Stratégie',
    subtitle: (
      <>
        On t’aide à te poser <strong>les bonnes questions</strong>.
      </>
    ),
    text: (
      <>
        Pour clarifier ce que tu veux pour ton business, ta vie, et comment l’obtenir.
      </>
    ),
    result: (
      <>
        Tu sais où tu vas, tu prends de meilleures décisions, et tu gardes
        le cap pour <strong>concrétiser tes ambitions</strong> pro et perso.
      </>
    ),
    color: 'orange',
    picto: strategyPillar,
  },
  {
    number: 2,
    title: 'Efficience',
    subtitle: (
      <>
        On t’aide à <strong>(re)structurer</strong> ton business.
      </>
    ),
    text: (
      <>
        Avec des <strong>outils</strong> et une <strong>organisation optimale</strong> adaptée à
        <strong>ta situation</strong>. Pour avancer <span className="text-decoration-underline">sereinement</span> !
      </>
    ),
    result: (
      <>
        Tu construis et optimises <strong>ton mode de travail idéal</strong> pour
        booster <strong>ton efficacité</strong> de
        manière <strong>sereine</strong> et <strong>durable</strong>.
      </>
    ),
    color: 'yellow',
    picto: efficiencyPillar,
  },
  {
    number: 3,
    title: 'Mindset',
    subtitle: (
      <>
        On t’aide à <strong>muscler</strong> ton mindset.
      </>
    ),
    text: (
      <>
        Pour gagner en <span className="ff--bold text-decoration-underline">confiance</span> et <strong>te sentir à la hauteur</strong>, même
        en cas de coup dur.
      </>
    ),
    result: (
      <>
        <strong>Tu affirmes ta posture</strong> de chef·fe d’entreprise et déployes (ENFIN !)
        tout <strong>ton potentiel</strong> pour atteindre <strong>tes objectifs</strong> même
        les plus ambitieux.
      </>
    ),
    color: 'blue',
    picto: mindsetPillar,
  },
  {
    number: 4,
    title: 'Émotions',
    subtitle: (
      <>
        On t’aide à <strong>comprendre et réguler</strong> tes émotions.
      </>
    ),
    text: (
      <>
        Ton anxiété et ton stress pour <span className="text-decoration-underline">ne plus te sentir</span> <strong>submergé·e</strong> et oppressé·e en permamence.
      </>
    ),
    result: (
      <>
        Tu te reconnectes à toi-même et apprivoises tes émotions et ton stress
        pour te sentir plus <strong>légè·re et serein·e dans ton quotidien</strong>.
      </>
    ),
    color: 'violet',
    picto: emotionsPillar,
  },
];

export default pillarCardData;
