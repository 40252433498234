const choices = [
  { value: 'Échec', color: 'blue' },
  { value: 'Je déteste', color: 'violet' },
  { value: 'Grosse galère', color: 'orange' },
  { value: 'Réussite', color: 'white' },
  { value: 'Coup d’audace', color: 'yellow' },
  { value: 'Faiblesse', color: 'blue' },
  { value: "J'adore", color: 'violet' },
  { value: 'JOKER', color: 'orange' },
  { value: 'Talent', color: 'white' },
  { value: 'Gain de temps', color: 'yellow' },
  { value: 'Ça me booste', color: 'blue' },
  { value: 'Perte de temps', color: 'violet' },
  { value: 'Pire rencontre', color: 'orange' },
  { value: 'Belle rencontre', color: 'white' },
  { value: 'Cauchemar', color: 'yellow' },
  { value: 'Grosse déception', color: 'blue' },
  { value: 'Meilleure stratégie', color: 'violet' },
  { value: 'Gros blocage', color: 'orange' },
  { value: 'Bonne surprise', color: 'white' },
  { value: 'Pire stratégie', color: 'yellow' },
  { value: 'Organisation', color: 'blue' },
  { value: 'Ça me plombe', color: 'violet' },
  { value: 'Rêve', color: 'orange' },
  { value: 'Grande satisfaction', color: 'white' },
  { value: 'Freestyle', color: 'yellow' },
  { value: 'Pire client', color: 'blue' },
  { value: 'Meilleur client', color: 'violet' },
];

const colorDef = {
  blue: '#029FB2',
  violet: '#DD0369',
  orange: '#F35C04',
  white: '#FFFFFF',
  yellow: '#F0B40E',
};

const data = choices.map((choice) => ({
  option: choice.value,
  color: choice.color,
  style: {
    backgroundColor: colorDef[choice.color],
    textColor: choice.color === 'white' ? '#000000' : '#FFFFFF',
    fontSize: 12,
  },
}));

export default data;
