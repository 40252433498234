const carouselTestimonialsData = [
  {
    id: 1,
    name: 'Solene',
    role: 'Dirigeante',
    pitch: (
      <>
        "
        <strong>
          J’ai retrouvé le cœur qui palpite, l’envie et les yeux qui pétillent
        </strong>
        .
        J'ai <strong>gagné en efficacité</strong> dans la gestion des priorités.
        Je ne suis plus en mode pompier en permanence et
        je n’ai plus le sentiment de courir après le temps.
        <strong> Je trouve plus de fluidité et de clarté dans mon quotidien </strong>.
        J’aborde ma vie professionnelle de façon&nbsp;
        <strong>beaucoup plus sereine et je retrouve du plaisir</strong>."
      </>
    ),
  },
  {
    id: 2,
    name: 'Alice',
    role: 'Styliste indépendante',
    pitch: (
      <>
        "<strong>J’ai eu un déclic</strong>. J’ai réussi à <strong>poser un cadre </strong>
        dans ma vie professionnelle. J’ai <strong>surpassé ma difficulté à dire NON </strong>
        et cela me permet d’<strong>avancer plus facilement </strong>.
        En plus, Vanessa est un rayon de soleil."
      </>
    ),
  },
  {
    id: 3,
    name: 'Rémi',
    role: 'Dirigeant de Startup',
    pitch: (
      <>
        "J’avais <strong>un problème à vivre avec des choses à faire</strong> sur ma to-do list.
        Maintenant, je sais <strong>accorder la bonne importance aux choses</strong> et vivre
        avec des tâches, <strong>sans avoir à tout faire</strong>.
        C’est <strong>une délivrance en termes de charge mentale</strong>."
      </>
    ),
  },
  {
    id: 4,
    name: 'Isabelle',
    role: 'Dirigeante',
    pitch: (
      <>
        "On ne peut pas dire que c'est du coaching,
        c’est tellement plus que ça ! C'est <strong>une prise en charge globale</strong> qui m'
        apporte des prises de conscience, conseils, supports pratiques,
        psychologiques et beaucoup de sécurité en tant que dirigeante
        d'entreprise et aussi en tant que femme."
      </>
    ),
  },
  {
    id: 5,
    name: 'Sandra',
    role: 'Entrepreneure',
    pitch: (
      <>
        "Avant l’accompagnement, je me sentais submergée, fatiguée et dépassée
        par le lancement de mon entreprise. Je travaillais énormément, sans grand résultat.
        Les outils proposés m’ont permis de mettre en place
        des <strong>actions concrètes</strong> de prise de conscience et d’évolution.
        Je me suis vraiment sentie accompagnée et coachée. D’autre part,
        les outils et actions  proposés m’ont permis de <strong>prendre du recul</strong> et
        d’avancer dans la construction de mon organisation et dans mes actions entrepreneuriales."
      </>
    ),
  },
  {
    id: 6,
    name: 'Romain',
    role: 'Dirigeant',
    pitch: (
      <>
        "J’ai réussi à <strong>dépasser des blocages ancrés</strong> et ça me
        permet de me sentir beaucoup mieux dans mon quotidien. Je suis moins stressé et anxieux.
        <strong>J’ai retrouvé du plaisir</strong> dans mon travail et au quotidien. Je ne
        culpabilise plus de me détendre. Je me sens <strong>libre</strong> de faire ce que
        j’ai envie, dans ma vie. La balance investissement-résultat vaut largement le coup."
      </>
    ),
  },
];

export default carouselTestimonialsData;
