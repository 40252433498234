import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  Container, Row, Col, Image, Button, Card,
} from 'react-bootstrap';

import { IconContext } from 'react-icons';
import {
  FaCheckCircle, FaArrowAltCircleRight, FaArrowAltCircleDown, FaEye, FaCheck, FaHandPointDown,
} from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { BsFillEmojiDizzyFill, BsEmojiSunglassesFill } from 'react-icons/bs';
import { GoDotFill } from 'react-icons/go';

import { InlineWidget } from 'react-calendly';
import vanessaInRed from '../../assets/img/vanessa-in-red.webp';
import vanessaJump from '../../assets/img/vanessa-jump.webp';
import logoWkcYellow from '../../assets/img/logo-wkc-yellow.webp';
import diagPicto from '../../assets/img/diag-picto.webp';
import vanessaPointerFingers from '../../assets/img/vanessa-pointer-fingers.webp';

import Divider from '../../components/Divider';
import Counter from '../../components/Counter';
import CarouselTestimonials from '../../components/CarouselTestimonials';
import carouselTestimonialsData from '../../data/carouselTestimonialsData';
import TalkingAboutWkc from '../../components/TalkingAboutWkc';
import talkingAboutWkcData from '../../data/talkingAboutWkcData';
import WkcEcosystem from './WkcEcosystem';
import wkcEcosystemData from './WkcEcosystem/wkcEcosystemData';
import TeamWkc from '../../components/TeamWkc';
import teamWkcData from '../../data/teamWkcData';

import {
  sellPageMyCoolPilotLink, sellPageHappyCultureLink, testimonialsLink, workinDiagLink, aboutUsLink,
} from '../../components/Router/routes';

import useScrollToTop from '../../hooks/useScrollToTop';
import useBreakpoint from '../../hooks/useBreakpoint';

import './styles.scss';

const Home = () => {
  const checkRoundBlueCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'me-2 checkblue text-wkc-blue',
  }), []);
  const arrowCSS = useMemo(() => ({
    size: '1.2rem',
    className: 'ms-2',
  }), []);
  const eyeCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'ms-2',
  }), []);
  const crossCSS = useMemo(() => ({
    size: '1.2rem',
    className: 'me-2 text-wkc-darkviolet',
  }), []);
  const checkBlueCSS = useMemo(() => ({
    size: '1.2rem',
    className: 'me-2 text-wkc-blue',
  }), []);
  const fingerCSS = useMemo(() => ({
    size: '1.5rem',
    className: 'ms-2 text-wkc-yellow',
  }), []);
  const emojiDarkVioletCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'ms-2 text-wkc-darkviolet',
  }), []);
  const dotDarkVioletCSS = useMemo(() => ({
    size: '1.2rem',
    className: 'me-2 text-wkc-darkviolet',
  }), []);
  const dotBlueCSS = useMemo(() => ({
    size: '1.2rem',
    className: 'me-2 text-wkc-blue',
  }), []);
  const emojiBlueCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'ms-2 text-wkc-blue',
  }), []);

  const breakpoint = useBreakpoint();
  useScrollToTop();

  return (
    <div className="home">
      <header className="home__header bg-wkc-yellow position-relative">
        <Container>
          <Row>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 4, order: 1 }}
              className="text-center text-md-start"
            >
              <Image
                className="vanessa"
                src={vanessaInRed}
                alt="Vanessa"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 2 }}
              className="d-flex flex-column justify-content-center my-4 mt-md-0 mb-md-5"
            >
              <h1 className="text-uppercase">
                On a <span className="lighted--violet">tous</span> le droit<br />
                de se sentir <span className="text-wkc-violet">bien</span><br />
                <strong>au travail</strong>
              </h1>
              <h4 className="mt-3">
                Des solutions <strong>adaptées</strong> aux besoins<br />
                des entrepreneurs, dirigeants, cadres et managers
              </h4>
              <Row className="ff--bold fs-5 my-3">
                <Col
                  xs={{ span: 6, offset: 3 }}
                  md={{ span: 4, offset: 0 }}
                >
                  <IconContext.Provider value={checkRoundBlueCSS}>
                    <FaCheckCircle />
                  </IconContext.Provider>
                  Performance
                </Col>
                <Col
                  xs={{ span: 6, offset: 3 }}
                  md={{ span: 4, offset: 0 }}
                  className="my-2 my-md-0"
                >
                  <IconContext.Provider value={checkRoundBlueCSS}>
                    <FaCheckCircle />
                  </IconContext.Provider>
                  Liberté
                </Col>
                <Col
                  xs={{ span: 6, offset: 3 }}
                  md={{ span: 4, offset: 0 }}
                >
                  <IconContext.Provider value={checkRoundBlueCSS}>
                    <FaCheckCircle />
                  </IconContext.Provider>
                  Bien-être
                </Col>
              </Row>
              <div className="buttons-wrapper">
                <div className="buttons-container">
                  <Button
                    variant="wkc-violet"
                    className="slide-button"
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      Découvre nos solutions
                      {
                        breakpoint > 768 ? (
                          <IconContext.Provider value={arrowCSS}>
                            <FaArrowAltCircleRight />
                          </IconContext.Provider>
                        ) : (
                          <IconContext.Provider value={arrowCSS}>
                            <FaArrowAltCircleDown />
                          </IconContext.Provider>
                        )
                      }
                    </div>
                  </Button>
                  <Link
                    to={sellPageMyCoolPilotLink}
                    target="_blank"
                    className="slide-button"
                  >
                    <Button
                      variant="wkc-violet"
                    >
                      My Cool' Pilot
                    </Button>
                  </Link>
                  <Link
                    to={sellPageHappyCultureLink}
                    target="_blank"
                    className="slide-button"
                  >
                    <Button
                      variant="wkc-violet"
                    >
                      Happy' Culture
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Divider color="white" />
      </header>
      <section className="section-1 py-5">
        <Container>
          <Row className="justify-content-evenly">
            <Col xs={12} md={3} className="text-center text-md-start">
              <h2>
                <span className="lighted--violet ff--bold">
                  + <Counter end={13} duration={1000} /> ans
                </span>
              </h2>
              <h4 className="text-uppercase">
                d’expertise dans<br />
                l’accompagnement
              </h4>
            </Col>
            <Col xs={12} md={3} className="text-center text-md-start my-4 my-md-0">
              <h2>
                <span className="lighted--violet ff--bold">
                  + <Counter end={200} duration={1000} />
                </span>
              </h2>
              <h4 className="text-uppercase">
                Entrepreneurs,<br />
                dirigeants et<br />
                Managers <strong className="text-wkc-violet">ravis</strong>
              </h4>
            </Col>
            <Col xs={12} md={3} className="text-center text-md-start">
              <h2>
                <span className="lighted--violet ff--bold">
                  <Counter end={1} duration={200} />
                </span>
              </h2>
              <h4 className="text-uppercase">
                Méthode qui a<br />
                fait ses preuves
              </h4>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-2 pb-5 pt-5 pt-md-0 bg-wkc-lightviolet">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <CarouselTestimonials
                borderColor="white"
                controlColor="white"
                carouselTestimonialsData={carouselTestimonialsData}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={4} className="text-center">
              <Link
                to={testimonialsLink}
                target="_blank"
              >
                <Button variant="wkc-darkviolet">
                  <strong className="d-flex align-items-center">
                    Plus de témoignages
                    <IconContext.Provider value={eyeCSS}>
                      <FaEye />
                    </IconContext.Provider>
                  </strong>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-3">
        <Container>
          <Row>
            <Col xs={12} md={4} className="position-relative">
              <div className="yellow-zone" />
              <Image
                className="vanessa mb-3"
                src={vanessaJump}
                alt="Vanessa"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col xs={0} md={1} />
            <Col xs={12} md={7} className="d-flex flex-column justify-content-center">
              <h2 className="text-uppercase mb-5">
                La <span className="text-wkc-violet">vraie</span> performance n'est pas<br />
                d'atteindre ses objectifs<br />
                <br />
                Mais d'y arriver <span className="text-wkc-violet">sans tout sacrifier</span>
              </h2>
              <h4 className="ff--bold text-uppercase">
                Après avoir accompagné<br />
                <span className="text-wkc-violet">
                  + de 200 entrepreneurs, dirigeants & managers
                </span>
              </h4>
              <p>
                Nous avons acquis la certitude qu’entreprendre et manager<br />
                des équipes avec succès, ça n’a rien avoir avec :
              </p>
              <ul className="ps-0">
                <li className="d-flex align-items-center">
                  <div>
                    <IconContext.Provider value={crossCSS}>
                      <ImCross />
                    </IconContext.Provider>
                  </div>
                  Le nombres d’heures travaillées.
                </li>
                <li className="d-flex align-items-center my-3">
                  <div>
                    <IconContext.Provider value={crossCSS}>
                      <ImCross />
                    </IconContext.Provider>
                  </div>
                  Les litres de sueurs dépensés.
                </li>
                <li className="d-flex align-items-center">
                  <div>
                    <IconContext.Provider value={crossCSS}>
                      <ImCross />
                    </IconContext.Provider>
                  </div>
                  La quantité de sacrifices.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-4 py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <h3>
                Pour réussir la seule chose nécessaire c'est<br />
                <strong className="text-uppercase">apprendre à travailler mieux</strong>
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-center my-5">
            <Col xs={6} md={4} className="text-center">
              <h4 className="text-uppercase ff--bold-italic text-wkc-yellow text-start">
                La méthode
              </h4>
              <Image
                className="logo-wkc-yellow"
                src={logoWkcYellow}
                alt="Logo Workin'Cool"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <p>
                Vous aide à opérer les changements nécessaires pour...
              </p>
              <ul className="ps-0">
                <li className="d-flex align-items-center">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                  Rompre avec l’effet “tête dans le guidon”.
                </li>
                <li className="d-flex align-items-center">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                  Agir concrètement pour améliorer votre qualité de vie.
                </li>
                <li className="d-flex align-items-center">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                  (Re)trouver bien-être et sérénité au travail.
                </li>
                <li className="d-flex align-items-center">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                  Prendre soin de votre santé mentale.
                </li>
              </ul>
              <h5 className="ff--bold text-uppercase text-center my-5">
                ça donne ça
                <IconContext.Provider value={fingerCSS}>
                  <FaHandPointDown />
                </IconContext.Provider>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={4}>
              <Card className="border--darkviolet">
                <Card.Body>
                  <h5 className="ff--bold">
                    <span className="text-uppercase d-flex align-items-center">
                      Avant
                      <IconContext.Provider value={emojiDarkVioletCSS}>
                        <BsFillEmojiDizzyFill />
                      </IconContext.Provider>
                    </span>
                  </h5>
                  <p>
                    <strong>Votre mode de travail génère :</strong>
                  </p>
                  <ul className="ps-0">
                    <li className="d-flex align-items-center">
                      <div>
                        <IconContext.Provider value={dotDarkVioletCSS}>
                          <GoDotFill />
                        </IconContext.Provider>
                      </div>
                      Perte de temps, éparpillement.
                    </li>
                    <li className="d-flex align-items-center">
                      <div>
                        <IconContext.Provider value={dotDarkVioletCSS}>
                          <GoDotFill />
                        </IconContext.Provider>
                      </div>
                      Stress intense et nuit blanches.
                    </li>
                    <li className="d-flex align-items-center">
                      <div>
                        <IconContext.Provider value={dotDarkVioletCSS}>
                          <GoDotFill />
                        </IconContext.Provider>
                      </div>
                      Tensions et difficultés de communication.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={0} md={2} />
          </Row>
          <Row className="justify-content-end my-5">
            <Col xs={12} md={4}>
              <Card className="border--blue">
                <Card.Body>
                  <h5 className="ff--bold">
                    <span className="text-uppercase d-flex align-items-center">
                      Après
                      <IconContext.Provider value={emojiBlueCSS}>
                        <BsEmojiSunglassesFill />
                      </IconContext.Provider>
                    </span>
                  </h5>
                  <p>
                    <strong>Votre mode de travail sur mesure pour :</strong>
                  </p>
                  <ul className="ps-0">
                    <li className="d-flex align-items-center">
                      <div>
                        <IconContext.Provider value={dotBlueCSS}>
                          <GoDotFill />
                        </IconContext.Provider>
                      </div>
                      Travailler avec efficacité, plaisir et sérénité.
                    </li>
                    <li className="d-flex align-items-center">
                      <div>
                        <IconContext.Provider value={dotBlueCSS}>
                          <GoDotFill />
                        </IconContext.Provider>
                      </div>
                      Gérer les urgences et imprévus sans paniquer.
                    </li>
                    <li className="d-flex align-items-center">
                      <div>
                        <IconContext.Provider value={dotBlueCSS}>
                          <GoDotFill />
                        </IconContext.Provider>
                      </div>
                      Collaborer de façon fluide et bienveillante.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={0} md={2} />
          </Row>
        </Container>
      </section>
      <section className="section-5 py-5 bg-wkc-yellow">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <h2 className="text-uppercase text-center">
                On ne laisse personne sur le carreau...
              </h2>
              <h3 className="text-center">
                Découvrez nos <strong>accompagnements cousus main</strong> pour
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-evenly">
            <Col xs={12} md={5} className="section-5__cadre bg-wkc-violet my-3">
              <h4 className="text-uppercase text-white text-center ff--bold mb-0 py-2">
                Entrepreneurs & Dirigeants
              </h4>
            </Col>
            <Col xs={12} md={5} className="section-5__cadre bg-wkc-blue my-3">
              <h4 className="text-uppercase text-white text-center ff--bold mb-0 py-2">
                Cadres & Managers
              </h4>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-6 py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <h4 className="text-uppercase ff--bold">
                On vous offre l’étape #1<br />
                pour trouver une solution efficace<br />
                <span className="lighted--yellow">un état des lieux</span> clair, précis et complet
              </h4>
            </Col>
          </Row>
          <Row className="justify-content-center my-5">
            <Col xs={12} md={4} className="d-flex flex-column">
              <h2 className="text-uppercase fs-1 text-center">
                <strong className="fs-1">LE</strong>
                <Image
                  className="diag-picto"
                  src={diagPicto}
                  alt="Workin'Diag"
                  fluid
                  loading="lazy"
                  decoding="async"
                />
              </h2>
              <h2 className="text-uppercase ff--italic text-center">
                Workin' Diag
              </h2>
            </Col>
            <Col xs={12} md={4} className="d-flex align-items-center justify-content-center mt-3 mt-md-0">
              <p className="fs-5 text-center text-md-start">
                Une analyse complète.<br />
                De votre mode de fonctionnement.<br />
                Vos atouts et vos enjeux.<br />
                Debrief avec un coach.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={4}>
              <h3 className="text-uppercase text-center ff--bold">
                <span className="lighted--yellow text-wkc-black">pour 0 €</span>
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-evenly my-3">
            <Col xs={12} md={5} className="my-3 d-flex">
              <Link
                to={workinDiagLink}
                target="_blank"
                className="flex-fill d-flex text-decoration-none"
              >
                <Button variant="wkc-violet" className="flex-fill">
                  <span className="fs-4">
                    Entrepreneurs<br />
                    ou Dirigeants
                  </span>
                </Button>
              </Link>
            </Col>
            <Col xs={12} md={5} className="my-3 d-flex">
              <Button variant="wkc-blue" className="flex-fill" disabled>
                <span className="fs-4">
                  Cadres<br />
                  ou Managers
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-7 py-5 bg-wkc-lightblue">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={4}>
              <h2 className="text-center text-uppercase text-white mb-4">
                Ils parlent de nous
              </h2>
            </Col>
          </Row>
        </Container>
        <TalkingAboutWkc talkingAboutWkcData={talkingAboutWkcData} />
      </section>
      <section className="section-8 py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <h4 className="text-uppercase ff--bold">
                L'écosystème Wokin'Cool
              </h4>
              <p>
                Retrouvez tous les outils, astuces et stratégies pour
                travailler de manière plus sereine, efficace et durable.
              </p>
            </Col>
          </Row>
        </Container>
        <WkcEcosystem wkcEcosystemData={wkcEcosystemData} />
      </section>
      <section className="section-9 py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <h4 className="text-uppercase ff--bold">
                tout cela ne serait pas possible sans une équipe de choc !
              </h4>
              <p className="fs-4">
                Découvrez l’histoire de Workin’Cool et l’équipe au complet
              </p>
            </Col>
          </Row>
        </Container>
        <TeamWkc teamWkcData={teamWkcData} />
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8} className="text-center">
              <Link
                to={aboutUsLink}
                target="_blank"
              >
                <Button variant="wkc-violet">
                  <strong className="text-uppercase ff--bold">
                    l'Histoire de Workin'Cool
                  </strong>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-10 pt-5">
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              <Image
                className="vanessa"
                src={vanessaPointerFingers}
                alt="Vanessa"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 2 }}
              className="text-center text-md-start d-flex flex-column justify-content-center ff--bold"
            >
              <h4 className="mb-5">
                Une activité performante<br />
                et sereine ?
              </h4>
              <h4>
                Réserve <span className="text-uppercase lighted--violet">ton café papote</span><br />
                C’est 100% cool et garanti sans engagement.
              </h4>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <InlineWidget
                url="https://calendly.com/vanessa-workincool/rendez-vous-avec-vanessa-workin-cool"
                styles={{
                  height: breakpoint > 768 ? '800px' : '1000px',
                  display: 'block',
                  margin: '0',
                  background: breakpoint > 768 ? '#9A0249' : '#FFFFFF',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Home;
