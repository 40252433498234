import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Col, Card } from 'react-bootstrap';

import { IconContext } from 'react-icons';
import { FaStar } from 'react-icons/fa';

import generateUUID from '../../../utils/generateUUID';

import './styles.scss';

const Stars = ({ number }) => {
  const starYellowCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'mx-1 text-wkc-yellow',
  }), []);

  return (
    <>
      {
        Array.from({ length: number }).map(() => (
          <IconContext.Provider value={starYellowCSS} key={generateUUID(6)}>
            <FaStar />
          </IconContext.Provider>
        ))
      }
    </>
  );
};

Stars.propTypes = {
  number: PropTypes.number.isRequired,
};

const GoogleAdvise = ({ googleAdviseData }) => (
  <>
    {
    googleAdviseData.map(({ rank, advise, user }) => (
      <Col xs={12} md={3} className="google-advise my-3" key={user}>
        <Card className="border--violet">
          <Card.Body className="">
            <div className="google-advise__stars mb-3">
              <Stars number={rank} />
            </div>
            <Card.Text>
              <em>
                {advise}
              </em>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text>
              <strong>{user}</strong>
            </Card.Text>
          </Card.Footer>
        </Card>
      </Col>
    ))
  }
  </>
);

GoogleAdvise.propTypes = {
  googleAdviseData: PropTypes.arrayOf(PropTypes.shape({
    rank: PropTypes.number,
    advise: PropTypes.string,
    user: PropTypes.string,
  })).isRequired,
};

export default GoogleAdvise;
