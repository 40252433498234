import cardMailsPrives from '../../../assets/img/card-mails-prives.webp';
import cardLaGrandeRoue from '../../../assets/img/card-la-grande-roue.webp';
import cardCadeaux from '../../../assets/img/card-cadeaux.webp';
import cardCollectionBaba from '../../../assets/img/card-collection-baba.webp';

const wkcEcosystemData = [
  {
    image: cardMailsPrives,

    title: 'Votre semaine en mieux',
    color: 'violet',
    text: '1 outil, 1 réflexion, 1 secret d’entrepreneur à tester tout de suite pour allier plaisir et stratégie.',
  },
  {
    image: cardLaGrandeRoue,
    title: 'Podcast la grande Roue',
    color: 'yellow',
    text: '1 semaine sur 2, un vrai entrepreneur fait tourner la roue et partage ses expériences sans tabous.',
  },
  {
    image: cardCadeaux,
    title: 'Vos ressources offertes',
    color: 'orange',
    text: 'Téléchargez des bonus gratuits pour booster et accélérer votre business.',
  },
  {
    image: cardCollectionBaba,
    title: 'Des manuels pour entrepreneur',
    color: 'blue',
    text: 'Développez vos compétences pour entreprendre de manière efficace, saine et durable.',
  },
];

export default wkcEcosystemData;
