import { useEffect, useState, useMemo } from 'react';

import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import {
  Form, FloatingLabel, Row, Col, Button,
} from 'react-bootstrap';

import { IconContext } from 'react-icons';
import { RiMailSendLine } from 'react-icons/ri';

import Loader from '../Loader';

import './styles.scss';

const ContactForm = ({
  submitForm, loading, success, error,
}) => {
  const iconCSS = useMemo(() => ({
    size: '1.2rem',
    className: 'ms-2',
  }), []);

  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    formState,
  } = useForm();

  const { isSubmitSuccessful, errors } = formState;

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  const [showFirstnameError, setShowFirstnameError] = useState(false);
  useEffect(() => {
    if (errors.firstname) {
      setShowFirstnameError(true);
    }
  }, [errors.firstname]);
  useEffect(() => {
    if (showFirstnameError) {
      setTimeout(() => {
        setShowFirstnameError(false);
      }, 3000);
    }
  }, [showFirstnameError]);

  const [showLastnameError, setShowLastnameError] = useState(false);
  useEffect(() => {
    if (errors.lastname) {
      setShowLastnameError(true);
    }
  }, [errors.lastname]);
  useEffect(() => {
    if (showLastnameError) {
      setTimeout(() => {
        setShowLastnameError(false);
      }, 3000);
    }
  }, [showLastnameError]);

  const [showEmailError, setShowEmailError] = useState(false);
  useEffect(() => {
    if (errors.email) {
      setShowEmailError(true);
    }
  }, [errors.email]);
  useEffect(() => {
    if (showEmailError) {
      setTimeout(() => {
        setShowEmailError(false);
      }, 3000);
    }
  }, [showEmailError]);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setFocus('name');
    const { log } = console;
    const {
      firstname, lastname, email, message,
    } = data;
    if (process.env.NODE_ENV === 'development') log(firstname, lastname, email, message);
    submitForm(firstname, lastname, email, message);
  };

  return (
    <div className="contact-form position-relative">
      {
        loading && <Loader color="white" loading={loading} />
      }
      {
        success && (
          <div className="contact-form__success">
            <h5 className="text-center text-white">
              <em>
                "Ton message a bien été envoyé à l'équipe Workin'Cool !"
              </em>
            </h5>
          </div>
        )
      }
      {
        error && (
          <div className="contact-form__error">
            <h5 className="text-center text-white">
              <em>
                "Une erreur s'est produite, recommence..."
              </em>
            </h5>
          </div>
        )
      }
      <Form
        className={`mt-4 text-center position-relative ${loading || success || error ? 'hide' : ''}`}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              {
                showFirstnameError ? <small className="text-white">Champs obligatoire...</small> : <small>&nbsp;</small>
              }
              <FloatingLabel
                controlId="floatingFirstName"
                label="Prénom *"
              >
                <Form.Control
                  type="text"
                  placeholder="Prénom"
                  {...register('firstname', { required: true })}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3">
              {
                showLastnameError ? <small className="text-white">Champs obligatoire...</small> : <small>&nbsp;</small>
              }
              <FloatingLabel
                controlId="floatingName"
                label="Nom *"
              >
                <Form.Control
                  type="text"
                  placeholder="Nom"
                  {...register('lastname', { required: true })}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          {
            showEmailError ? <small className="text-white">Champs obligatoire...</small> : <small>&nbsp;</small>
          }
          <FloatingLabel
            controlId="floatingEmail"
            label="Email *"
          >
            <Form.Control
              type="email"
              placeholder="Email"
              {...register('email', { required: true })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="my-5">
          <FloatingLabel
            controlId="floatingMessage"
            label="Message *"
          >
            <Form.Control
              as="textarea"
              placeholder="Message"
              style={{ height: '120px' }}
              {...register('message', { required: false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Button
          variant="wkc-yellow"
          type="submit"
        >
          Envoyer
          <IconContext.Provider value={iconCSS}>
            <RiMailSendLine />
          </IconContext.Provider>
        </Button>
      </Form>
    </div>

  );
};

ContactForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

export default ContactForm;
