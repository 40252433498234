import apiContact from '../api';

export const subscribeToNewsletter = async (firstname, email) => {
  const listLabels = ['NEWSLETTER'];
  try {
    const { data } = await apiContact.post('/contact/subscribe', {
      firstname,
      email,
      listLabels,
    });
    return {
      subscribeSuccess: {
        message: data.message,
        detail: data.detail,
      },
    };
  } catch (error) {
    return {
      subscribeError: {
        error,
      },
    };
  }
};

export const unsubscribeToNewsletter = async (email, why) => {
  try {
    const { data } = await apiContact.patch('/contact/unsubscribe', {
      email,
      why,
    });
    return {
      unsubscribeSuccess: {
        message: data.message,
        detail: data.detail,
      },
    };
  } catch (error) {
    return {
      unsubscribeError: {
        error,
      },
    };
  }
};
