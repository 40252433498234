import axios from 'axios';

const apiContact = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3004' : 'https://api.workincool.com/contact/v1',
  headers: {
    'X-Client-ID': 'WKC_CLIENT_ID_bbbe4966eef3b73e88464567fec8db74b706bd0ab20b0ebc98bd7618682f2a44',
  },
});

export default apiContact;
