// Internal routes
export const aboutUsLink = '/a-propos';
export const meetUsLink = '/nous-rencontrer';
export const deontologyLink = '/deontologie';
export const homeLink = '/';
export const legalNoticesLink = '/mentions-legales';
export const newsletterLink = '/mails-prives';
export const unsubscribeLink = '/desinscription';
export const sellPageHappyCultureLink = '/happy-culture';
export const testimonialsLink = '/temoignages';

// Podcast
export const podcastLink = '/podcast';
export const ferrisWheelLink = '/podcast/la-grande-roue';

// MCP routes
export const sellPageMyCoolPilotLink = '/my-cool-pilot';
export const joinMyCoolPilot = '/my-cool-pilot/join';
export const freeTrialMyCoolPilot = '/my-cool-pilot/free-trial';

// Tools routes
export const workinDiagLink = '//tools.workincool.com/workindiag';
export const testSyndromeImposteurLink = '//tools.workincool.com/test-syndromeimposteur';

// Social routes
export const instagramLink = '//www.instagram.com/workincool/';
export const linkedinLink = '//www.linkedin.com/in/vanessarocherieux/';

// Google routes
export const googleAdviseLink = 'https://www.google.fr/maps/place/Workin%27Cool%2B-%2BVanessa%2BRocherieux%2B-%2BCoaching%2Bprofessionnel/%4022.724843%2C-94.9484034%2C3z/data=%214m8%213m7%211s0x43efc14cefab4d67:0xc4fea127e826100%218m2%213d30.6959409%214d-49.8171262%219m1%211b1%2116s%252Fg%252F11q4j0l1zw?entry=ttu&g_ep=EgoyMDI0MTAxNi4wIKXMDSoASAFQAw%253D%253D';
