import { useEffect, useState } from 'react';

import {
  useLocation, useSearchParams,
} from 'react-router-dom';

import {
  Container, Row, Col,
} from 'react-bootstrap';

import UnsubscribeForm from '../../components/UnsubscribeForm';
import { unsubscribeToNewsletter } from '../../services/newsletter';

import './styles.scss';

const Unsubscribe = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (pathname === '/desinscription') {
      setEmail(searchParams.get('email'));
    }
  }, [pathname]);

  const handleUnsubscribe = async (why) => {
    try {
      setLoading(true);
      setSuccess(false);
      setFailed(false);
      const {
        unsubscribeSuccess,
        unsubscribeError,
      } = await unsubscribeToNewsletter(email, why);
      if (unsubscribeError) {
        throw new Error(unsubscribeError);
      }
      if (process.env.NODE_ENV === 'development') {
        const { log } = console;
        log(unsubscribeSuccess);
      }
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  useEffect(() => {
    if (failed) {
      setTimeout(() => {
        setLoading(false);
        setSuccess(false);
        setFailed(false);
      }, 3000);
    }
  }, [failed]);

  return (
    <div className="unsubscribe">
      <header className="bg-wkc-violet py-4">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8} className="text-center text-md-start text-white">
              <h1 className="fs-3">
                Nous sommes désolés <br className="d-md-none" />de te voir partir.
              </h1>
              <p>
                (Si tu changes d'avis, nous serons là pour t'accueillir
                à nouveau avec grand plaisir 😊)
              </p>
            </Col>
          </Row>
        </Container>
      </header>
      <main>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8} className="my-4">
              <p className="ff--bold mb-0 text-center text-md-start">
                Aide-nous à améliorer les mails privés de Workin'Cool
                en nous donnant <br className="d-md-none" />les raisons de ton départ :
              </p>
              <UnsubscribeForm
                submitForm={handleUnsubscribe}
                loading={loading}
                success={success}
                error={failed}
              />
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default Unsubscribe;
