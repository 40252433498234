import PropTypes from 'prop-types';

import { Card, Image } from 'react-bootstrap';

import './styles.scss';

const PillarCard = ({
  number, title, subtitle, text, result, color, picto,
}) => {
  const complementaryColor = {
    orange: 'blue',
    yellow: 'violet',
    blue: 'orange',
    violet: 'yellow',
  };

  return (
    <Card className={`pillar-card bg-wkc-${color} border--none`}>
      <Card.Header className={`bg-wkc-${color}`}>
        <Card.Title className="ff--bold text-uppercase mb-4" as="h2">
          Pilier<br />
          <span className={`text-wkc-${complementaryColor[color]}`}>#{number}</span>
        </Card.Title>
        <Card.Title as="h1" className="mb-4">
          <Image
            className="pillar-picto"
            src={picto}
            alt="Pictogramme du pilier"
            fluid
            loading="lazy"
            decoding="async"
          />
          {title}
        </Card.Title>
        <Card.Text className={`text-wkc-${color} bg-white`}>
          {subtitle}
        </Card.Text>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          {text}
        </Card.Text>
        <Card.Title className="text-uppercase ff--bold">
          Résultat
        </Card.Title>
        <Card.Text>
          {result}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

PillarCard.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.element.isRequired,
  text: PropTypes.element.isRequired,
  result: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
  picto: PropTypes.string.isRequired,
};

export default PillarCard;
