import mirrorEvent from '../../../assets/img/mirror-event.webp';
import cobrainEvent from '../../../assets/img/cobrain-event.webp';
import connectionEvent from '../../../assets/img/connection-event.webp';
import masterclassEvent from '../../../assets/img/masterclass-event.webp';
import toolsEvent from '../../../assets/img/tools-event.webp';
import messageEvent from '../../../assets/img/message-event.webp';
import onoetooneEvent from '../../../assets/img/onetoone-event.webp';

const eventCardData = [
  {
    picto: mirrorEvent,
    mainColor: 'violet',
    secondaryColor: 'wkc-lightviolet',
    frequency: (
      <strong>2 fois par mois *</strong>
    ),
    subtitle: (
      <strong>
        Pour prendre du recul, faire sauter des blocages et créer des déclics.
      </strong>
    ),
    content: (
      <>
        <p>
          Tu es <strong>coaché·e</strong> ou tu <strong>observes</strong> d’autres
          entrepreneurs <strong>se faire coacher</strong>.
        </p>
        <p>
          Une méthode ultra puissante pour :
        </p>
        <ul>
          <li> ● Normaliser les difficultés.</li>
          <li> ● Créer des prises de conscience.</li>
          <li> ● Identifier de nouvelles solutions.</li>
        </ul>
      </>
    ),
  },
  {
    picto: cobrainEvent,
    mainColor: 'orange',
    secondaryColor: 'wkc-lightorange',
    frequency: (
      <strong>2 fois par mois *</strong>
    ),
    subtitle: (
      <strong>
        Pour partager ses challenges et ses bonnes pratiques.
      </strong>
    ),
    content: (
      <>
        <p>
          Une expérience d'échanges, de <strong>soutien</strong> et de <strong>stimulation</strong>,
          où <strong>l'intelligence collective</strong> t'aide
          à <strong>surmonter tes défis</strong> et à prendre
          des <strong>décisions</strong> <strong>éclairées</strong> pro & perso.
        </p>
        <p>
          Accompagné par un coach,
          le groupe <strong>partage ses expériences, conseils et idées</strong> pour
          résoudre des problématiques communes.
        </p>
      </>
    ),
  },
  {
    picto: connectionEvent,
    mainColor: 'darkblue',
    secondaryColor: 'wkc-blue',
    frequency: (
      <strong>1 fois tous les 2 mois</strong>
    ),
    subtitle: (
      <strong>
        Pour créer un véritable réseau, tisser des liens et des synergies.
      </strong>
    ),
    content: (
      <>
        <p>
          Un moment <strong>privilégié</strong> en
          <strong>petit groupe pour échanger, tisser des liens</strong>,
          partager <strong>ses défis</strong> et grandir ensemble.
        </p>
        <p>
          Et pourquoi pas trouver des synergies, voire de nouveaux clients !
        </p>
      </>
    ),
  },
  {
    picto: masterclassEvent,
    mainColor: 'yellow',
    secondaryColor: 'wkc-lightyellow',
    frequency: (
      <strong>1 fois par mois</strong>
    ),
    subtitle: (
      <strong>
        Pour monter en compétences.
      </strong>
    ),
    content: (
      <>
        <p>
          Un <strong>coach</strong> ou un <strong>expert</strong>, t’aide
          à <strong>développer ton expertise</strong> sur des sujet
          liés à des <strong>situations réelles</strong> de ta vie d’entrepreneur·e.
        </p>
        <p>
          <strong>Quelques exemples :</strong><br />
          <em>”Gagner du temps avec l’automatisation.”</em><br />
          <em>“Surmonter sa peur de l’échec.”</em><br />
          <em>“Déléguer pour me consacrer à l’essentiel”</em>
        </p>
      </>
    ),
  },
  {
    picto: toolsEvent,
    mainColor: 'lightblack',
    secondaryColor: '',
    frequency: (
      <strong>Illimité</strong>
    ),
    subtitle: (
      <strong>
        Pour progresser à ton rythme et faciliter ton quotidien.

      </strong>
    ),
    content: (
      <p>
        Retrouve dans <strong>My Cool’App</strong> des ressources pour entrer
        en action et <strong>nos meilleurs outils</strong> pour te faciliter la vie.
      </p>
    ),
  },
  {
    picto: messageEvent,
    mainColor: 'darkviolet',
    secondaryColor: 'wkc-violet',
    frequency: (
      <strong>Illimité</strong>
    ),
    subtitle: (
      <strong>
        Pour ne plus jamais être seul·e face à ton ordinateur.
      </strong>
    ),
    content: (
      <>
        <p>
          <strong>Accède en illimité à ton coach</strong> via <strong>ton espace privé</strong> pour
          partager tes problématiques avec lui dès que tu en ressens le besoin.
        </p>
        <p>
          Tu bénéficies de <strong>réponses personnalisées</strong> pour avancer
          sereinement en étant toujours <strong>épaulé·e</strong>.
        </p>
      </>
    ),
  },
  {
    picto: onoetooneEvent,
    mainColor: 'blue',
    secondaryColor: 'wkc-lightblue',
    frequency: (
      <strong>Tout au long du parcours</strong>
    ),
    subtitle: (
      <strong>
        Pour être guidé·e sur tes problématiques de façon personnalisée.
      </strong>
    ),
    content: (
      <>
        <p>
          Tu profites <strong>immédiatement d’une séance</strong> de
          coaching quand tu rejoins My Cool’Pilot.
        </p>
        <p>
          Puis dès que tu en ressens le besoin
          grâce <strong>2 coachings joker tous les 6 mois</strong>.
        </p>
      </>
    ),
  },
];

export default eventCardData;
