import PropTypes from 'prop-types';

import { Modal, Image, Button } from 'react-bootstrap';

import wheelTitle from '../../assets/img/wheel-title.webp';

import './styles.scss';

const ModalWheel = ({
  show, hide, result, color,
}) => (
  <Modal
    className="modal-wheel"
    centered
    show={show}
    onHide={hide}
  >
    <Modal.Header>
      <Image
        className="modal-logo"
        src={wheelTitle}
        alt="La Grande Roue"
        fluid
        loading="lazy"
        decoding="async"
      />
      <Modal.Title>
        <strong className="text-wkc-violet">À toi de jouer !</strong>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className={`bg-wkc-${color}`}>
      <Modal.Title className="text-center text-white">
        <strong>{result}</strong>
      </Modal.Title>
    </Modal.Body>
    <Modal.Footer className="justify-content-center">
      <Button
        variant="wkc-violet"
        onClick={hide}
      >
        C'est parti !
      </Button>
    </Modal.Footer>
  </Modal>
);

ModalWheel.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  result: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default ModalWheel;
