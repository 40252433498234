import { useState } from 'react';

import {
  Container, Row, Col, Image, Button,
} from 'react-bootstrap';

import { Wheel } from 'react-custom-roulette';

import wheelTitle from '../../assets/img/wheel-title.webp';
import wheelFoot from '../../assets/img/wheel-foot.webp';
import wheelData from './wheelData';
import ModalWheel from './ModalWheel';

import './styles.scss';

const FerrisWheel = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [openModal, setOpenModal] = useState(true);

  const wheelSound = new Audio('/ferris/ferris-wheel-sound.mp3');

  const getRandomInteger = (min, max) => {
    const randomBytes = new Uint32Array(1);
    window.crypto.getRandomValues(randomBytes);
    return Math.floor((randomBytes[0] / (0xFFFFFFFF + 1)) * (max - min + 1)) + min;
  };

  const handleSpinClick = () => {
    if (!mustSpin) {
      setPrizeNumber(getRandomInteger(0, wheelData.length - 1));
      setMustSpin(true);
      wheelSound.play();
    }
  };

  const handleEndSpin = () => {
    setOpenModal(true);
    setMustSpin(false);
  };

  return (
    <div className="ferris-wheel">
      <ModalWheel
        show={openModal}
        hide={() => setOpenModal(false)}
        result={wheelData[prizeNumber].option}
        color={wheelData[prizeNumber].color}
      />
      <header className="ferris-wheel__header py-3">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6} className="text-center">
              <Image
                className="wheel-title"
                src={wheelTitle}
                alt="La Grande Roue"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
          </Row>
        </Container>
      </header>
      <main className="ferris-wheel__main">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8} className="wheel-box">
              <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={wheelData}
                outerBorderWidth={15}
                outerBorderColor="#A20E53"
                radiusLineWidth={1}
                radiusLineColor="rgba(0,0,0,0)"
                innerRadius={10}
                textDistance={50}
                spinDuration={1.5}
                pointerProps={{ src: '/ferris/pointer.webp' }}
                onStopSpinning={handleEndSpin}
              />
              <Image
                className="wheel-foot"
                src={wheelFoot}
                alt="Pied de la grande roue"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={6} className="text-center wheel-footer">
              <Button
                variant="wkc-violet"
                onClick={handleSpinClick}
              >
                Lancer la roue !
              </Button>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default FerrisWheel;
