import { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Row, Col, Card, Button, Form,
} from 'react-bootstrap';

import { freeTrialMyCoolPilot, joinMyCoolPilot } from '../../../components/Router/routes';

import './styles.scss';

const ToggleOffer = () => {
  const [plan, setPlan] = useState('try');

  const handleSwitch = (event) => {
    if (event.target.checked) {
      setPlan('go');
    } else {
      setPlan('try');
    }
  };

  return (
    <div className="toggle-offer">
      <Row className="justify-content-center">
        <Col xs={12} md={6} className="text-center text-white">
          <div className="toggle-offer__switch-container mt-4">
            <strong className={`me-3 ${plan !== 'try' && 'opacity-50'}`}>Active ton essai</strong>
            <Form.Switch
              className="fs-4"
              type="switch"
              id="switch"
              onChange={handleSwitch}
            />
            <strong className={`ms-3 ${plan !== 'go' && 'opacity-50'}`}>Rejoins l'aventure</strong>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-evenly my-5">
        <Col xs={12} md={5} className={`toggle-offer__try ${plan !== 'try' && 'fade-opacity'}`}>
          <Card className={`mb-4 bg-white border--yellow ${plan === 'try' && 'shadow-lg'}`}>
            <Card.Body className="border--rounded bg-white text-start">
              <Card.Title className="ff--bold text-uppercase text-center my-3">
                Active ton essai
              </Card.Title>
              <Card.Text className="fs-5 text-center">
                <strong className="text-wkc-violet">100 % gratuit</strong><br />
              </Card.Text>
              <Card.Text>
                Tu sens que <strong>My Cool’Pilot</strong> te correspond, mais tu as besoin
                de savoir où tu vas et de connaître le fonctionnement.
              </Card.Text>
              <Card.Text>
                Tu participes à une session de ton choix (coaching miroir ou co-brain)
              </Card.Text>
            </Card.Body>
            <Card.Footer className="bg-white text-center py-3">
              <span className="fs-5 lighted--violet text-uppercase text-white">C'est cadeau</span>
            </Card.Footer>
          </Card>
          {
            plan === 'try' ? (
              <Link
                to={freeTrialMyCoolPilot}
                target="_blank"
              >
                <Button
                  variant="wkc-yellow"

                >
                  <span className="text-uppercase">
                    J'active mon essai
                  </span>
                </Button>
              </Link>
            ) : (
              <Button
                variant="wkc-yellow"
                disabled
              >
                <span className="text-uppercase">
                  J'active mon essai
                </span>
              </Button>
            )
          }
        </Col>
        <Col xs={12} md={5} className={`toggle-offer__go ${plan !== 'go' && 'fade-opacity'}`}>
          <Card className={`mb-4 bg-white border--yellow ${plan === 'go' && 'shadow-lg'}`}>
            <Card.Body className=" border--rounded bg-white">
              <Card.Title className="ff--bold text-uppercase text-center my-3">
                Rejoins l'aventure
              </Card.Title>
              <Card.Text className="fs-5 text-center">
                <strong className="text-wkc-violet">6 mois d’accompagnement reconductible</strong><br />
              </Card.Text>
              <Card.Text className="text-start">
                Tu veux être accompagné·e et entouré·e sur le long
                terme vers une croissance durable.
              </Card.Text>
              <Card.Text>
                <span className="fs-5 lighted--violet text-uppercase text-white">
                  Tu accèdes <br />immédiatement à :
                </span>
              </Card.Text>
              <Card.Text className="text-start">
                <strong>Tous les évènements My Cool’Pilot</strong>,
                aux <strong>ressources et outils</strong>, et
                aux <strong>2 séances</strong> de coaching individuel joker que tu peux
                utiliser <strong>quand tu en ressens le besoin</strong>.
              </Card.Text>
            </Card.Body>
            <Card.Footer className="bg-white text-center py-3">
              <strong className="fs-5 text-uppercase">6 x 350€ HT</strong><br />
              <span className="fst-italic">Soit 2100€ HT</span>
              <br />
              <br />
              <span>
                🎁 1 coaching "one to one" joker supplémentaire<br />
                pour tout paiement en 1 fois.
              </span>
            </Card.Footer>
          </Card>
          {
            plan === 'go' ? (
              <Link
                to={joinMyCoolPilot}
                target="_blank"
              >
                <Button
                  variant="wkc-yellow"

                >
                  <span className="text-uppercase">
                    Je rejoins l'aventure
                  </span>
                </Button>
              </Link>
            ) : (
              <Button
                variant="wkc-yellow"
                disabled
              >
                <span className="text-uppercase">
                  Je rejoins l'aventure
                </span>
              </Button>
            )
          }
        </Col>
      </Row>
    </div>
  );
};

export default ToggleOffer;
