import PropTypes from 'prop-types';

import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

import './styles.scss';

const TalkingAboutWkc = ({ talkingAboutWkcData }) => (
  <div className="talking-about-wkc">
    <Container>
      <Row className="justify-content-evenly">
        {
          talkingAboutWkcData.map(({ image, alt }) => (
            <Col
              xs={12}
              md={2}
              className="text-center d-flex align-items-center justify-content-center"
              key={alt}
            >
              <Image
                className={`logo logo-${alt}`}
                src={image}
                alt={alt}
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
          ))
        }
      </Row>
    </Container>
  </div>
);

TalkingAboutWkc.propTypes = {
  talkingAboutWkcData: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    alt: PropTypes.string,
  })).isRequired,
};

export default TalkingAboutWkc;
