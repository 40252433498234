import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import {
  Form, Button, Collapse,
} from 'react-bootstrap';

import Loader from '../Loader';
import labelsData from './labelsData';

import './styles.scss';

const UnsubscribeForm = ({
  submitForm, loading, success, error,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState,
  } = useForm();

  const { isSubmitSuccessful, errors } = formState;

  const [showDetail, setShowDetail] = useState(false);

  useEffect(() => {
    reset();
    setShowDetail(false);
  }, [isSubmitSuccessful]);

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (errors.option) {
      setShowError(true);
    }
  }, [errors.option]);
  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  }, [showError]);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    const { log } = console;
    const {
      option,
      detail,
    } = data;
    const why = Number(option) === 4
      ? detail
      : labelsData.find(({ id }) => id === Number(option)).label;
    if (process.env.NODE_ENV === 'development') log(why);
    submitForm(why);
  };

  return (
    <div className="unsubscribe-form position-relative">
      {
        loading && <Loader color="violet" loading={loading} />
      }
      {
        success && (
          <div className="unsubscribe-form__success">
            <h5 className="text-center text-wkc-violet">
              <em>
                "Tu es bien désinscrit·e aux mails privés Workin'Cool."
              </em>
            </h5>
          </div>
        )
      }
      {
        error && (
          <div className="unsubscribe-form__error">
            <h5 className="text-center text-wkc-violet">
              <em>
                "Une erreur s'est produite, recommence..."
              </em>
            </h5>
          </div>
        )
      }
      <Form
        className={`position-relative ${loading || success || error ? 'hide' : ''}`}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {
          showError ? <small className="text-wkc-violet">Merci de faire un choix...</small> : <small>&nbsp;</small>
        }
        {
          labelsData.map(({ id, label }) => (
            <Form.Check
              key={id}
              name="unsubscribe"
              type="radio"
              label={label}
              {...register('option', { required: true })}
              value={id}
              onChange={() => setShowDetail(id === 4)}
            />
          ))
        }
        <Collapse in={showDetail}>
          <Form.Group className="mt-2">
            <Form.Control
              as="textarea"
              style={{ height: '120px' }}
              {...register('detail', { required: showDetail })}
            />
          </Form.Group>
        </Collapse>
        <Button
          variant="wkc-violet"
          type="submit"
          className="mt-3"
        >
          Confirmer
        </Button>
      </Form>
    </div>
  );
};

UnsubscribeForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

export default UnsubscribeForm;
