import { useEffect, useState, useMemo } from 'react';

import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import {
  Form, FloatingLabel, Button,
} from 'react-bootstrap';

import { IconContext } from 'react-icons';
import { MdOutlineRocketLaunch } from 'react-icons/md';

import Loader from '../Loader';

import './styles.scss';

const SubscribeForm = ({
  submitForm, loading, success, error,
}) => {
  const iconCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'ms-2',
  }), []);

  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    formState,
  } = useForm();

  const { isSubmitSuccessful, errors } = formState;

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  const [showFirstnameError, setShowFirstnameError] = useState(false);
  useEffect(() => {
    if (errors.firstname) {
      setShowFirstnameError(true);
    }
  }, [errors.firstname]);
  useEffect(() => {
    if (showFirstnameError) {
      setTimeout(() => {
        setShowFirstnameError(false);
      }, 3000);
    }
  }, [showFirstnameError]);

  const [showEmailError, setShowEmailError] = useState(false);
  useEffect(() => {
    if (errors.email) {
      setShowEmailError(true);
    }
  }, [errors.email]);
  useEffect(() => {
    if (showEmailError) {
      setTimeout(() => {
        setShowEmailError(false);
      }, 3000);
    }
  }, [showEmailError]);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setFocus('name');
    const { log } = console;
    const {
      firstname, email,
    } = data;
    if (process.env.NODE_ENV === 'development') log(firstname, email);
    submitForm(firstname, email);
  };

  return (
    <div className="subscribe-form position-relative">
      {
        loading && <Loader color="white" loading={loading} />
      }
      {
        success && (
          <div className="subscribe-form__success">
            <h5 className="text-center text-white">
              <em>
                "Tu es bien inscrit·e aux mails privés Workin'Cool ! Check ta boîte mail !"
              </em>
            </h5>
          </div>
        )
      }
      {
        error && (
          <div className="subscribe-form__error">
            <h5 className="text-center text-white">
              <em>
                "Une erreur s'est produite, recommence..."
              </em>
            </h5>
          </div>
        )
      }
      <Form
        className={`mt-4 text-center position-relative ${loading || success || error ? 'hide' : ''}`}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Form.Group className="mb-3">
          {
            showFirstnameError ? <small className="text-white">Champs obligatoire...</small> : <small>&nbsp;</small>
          }
          <FloatingLabel
            controlId="floatingFirstName"
            label="Prénom *"
          >
            <Form.Control
              type="text"
              placeholder="Prénom"
              {...register('firstname', { required: true })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3">
          {
            showEmailError ? <small className="text-white">Champs obligatoire...</small> : <small>&nbsp;</small>
          }
          <FloatingLabel
            controlId="floatingEmail"
            label="Email *"
          >
            <Form.Control
              type="email"
              placeholder="Email"
              {...register('email', { required: true })}
            />
          </FloatingLabel>
        </Form.Group>
        <Button
          variant="wkc-yellow"
          type="submit"
        >
          Je m'inscris
          <IconContext.Provider value={iconCSS}>
            <MdOutlineRocketLaunch />
          </IconContext.Provider>
        </Button>
      </Form>
    </div>

  );
};

SubscribeForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};

export default SubscribeForm;
