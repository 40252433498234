import { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

import './styles.scss';

const WkcEcosystem = ({ wkcEcosystemData }) => (
  <div className="wkc-ecosystem">
    <Container>
      <Row className="justify-content-center">
        {
          wkcEcosystemData.map(({
            image, title, color, text,
          }, index) => (
            <Fragment key={title}>
              {
                (index === 0 || index === 2) && <Col xs={0} md={2} />
              }
              <Col xs={12} md={3} className="my-3">
                <Image
                  className="ecosystem-image"
                  src={image}
                  alt={title}
                  fluid
                  loading="lazy"
                  decoding="async"
                />
                <h5 className={`ff--bold text-wkc-${color} text-uppercase mt-3`}>
                  {title}
                </h5>
                <p>
                  {text}
                </p>
              </Col>
              <Col xs={0} md={2} />
            </Fragment>
          ))
        }
      </Row>
    </Container>
  </div>
);

WkcEcosystem.propTypes = {
  wkcEcosystemData: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
};

export default WkcEcosystem;
