import apiContact from '../api';

const sendMessage = async (firstname, lastname, email, message) => {
  try {
    const { data } = await apiContact.post('/message', {
      firstname,
      lastname,
      email,
      message,
    });
    return {
      sendMessageSuccess: {
        message: data.message,
        detail: data.detail,
      },
    };
  } catch (error) {
    return {
      sendMessageError: {
        error,
      },
    };
  }
};

export default sendMessage;
