import PropTypes from 'prop-types';
import { Carousel, Image, Card } from 'react-bootstrap';

import './styles.scss';

const CarouselApp = ({ carouselAppData }) => (
  <Carousel
    fade
    interval={1000}
    controls={false}
    indicators={false}
    className="carousel-app"
  >
    {
      carouselAppData.map(({ image, caption }) => (
        <Carousel.Item key={caption}>
          <Card className="border--yellow">
            <Card.Body>
              <Image
                className="slide"
                src={image}
                alt="Slide n°1"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Card.Body>
          </Card>
          <h4 className="text-center mt-3">
            <strong className="lighted--violet">{caption}</strong>
          </h4>
        </Carousel.Item>
      ))
    }
  </Carousel>
);

CarouselApp.propTypes = {
  carouselAppData: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
  })).isRequired,
};

export default CarouselApp;
