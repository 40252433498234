import PropTypes from 'prop-types';
import './styles.scss';

const Divider = ({ color }) => (
  <div className="custom-shape-divider-bottom">
    <svg data-name="Layer 1" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className={`shape-fill ${color}`} />
    </svg>
  </div>
);

Divider.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Divider;
