import { useState, useEffect } from 'react';

import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

import logoWkcYellow from '../../assets/img/logo-wkc-yellow.webp';
import actionPicture from '../../assets/img/action.webp';

import SubscribeForm from '../../components/SubscribeForm';
import { subscribeToNewsletter } from '../../services/newsletter';

import './styles.scss';

const Newsletter = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const handleSubscribe = async (firstname, email) => {
    try {
      setLoading(true);
      setSuccess(false);
      setFailed(false);
      const {
        subscribeSuccess,
        subscribeError,
      } = await subscribeToNewsletter(firstname, email);
      if (subscribeError) {
        throw new Error(subscribeError);
      }
      if (process.env.NODE_ENV === 'development') {
        const { log } = console;
        log(subscribeSuccess);
      }
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setFailed(true);
    }
  };

  useEffect(() => {
    if (failed) {
      setTimeout(() => {
        setLoading(false);
        setSuccess(false);
        setFailed(false);
      }, 3000);
    }
  }, [failed]);

  return (
    <div className="newsletter">
      <header className="newsletter__header bg-wkc-violet py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={4} className="d-flex align-items-center justify-content-center">
              <Image
                className="picture"
                src={logoWkcYellow}
                alt="Logo Workin'Cool"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col xs={12} md={4} className="d-flex flex-column align-items-center justify-content-around">
              <h3 className="text-center text-white">
                Ta semaine <span className="text-uppercase">en mieux</span> avec
              </h3>
              <h1 className="text-uppercase text-center">
                <strong className="lighted--yellow text-wkc-black">Les mails privés</strong>
              </h1>
            </Col>
            <Col xs={12} md={4} className="d-flex align-items-center justify-content-center">
              <Image
                className="picture"
                src={actionPicture}
                alt="Et action !"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
          </Row>
        </Container>
      </header>
      <section className="newsletter__content bg-wkc-violet pb-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <h5 className="text-white text-center">
                Inscris-toi et reçois un dimanche sur deux ton shot de cool vibes !
              </h5>
              <SubscribeForm
                submitForm={handleSubscribe}
                loading={loading}
                success={success}
                error={failed}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Newsletter;
