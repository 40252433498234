import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Counter = ({ end, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const incrementTime = Math.ceil(duration / end);

    const counter = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount >= end) {
          clearInterval(counter);
          return end;
        }
        return prevCount + 1;
      });
    }, incrementTime);

    return () => clearInterval(counter);
  }, [end, duration]);

  return (
    <span>
      {count}
    </span>
  );
};

Counter.propTypes = {
  end: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
};

export default Counter;
